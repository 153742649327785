import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { PreSignupFlowContext } from '@/components/PreSignupFlow/PreSignupProvider';
import Branding from '@/components/SimplifiedSignup/Components/Branding';
import LoggedInAs from '@/components/purchase/LoggedInAs';
import PaymentElementsFormRefresh from '@/components/purchase/PaymentElementsFormRefresh';
import { PurchaseFormSectionWrapper } from '@/components/purchase/PaymentElementsFormRefresh/styles';
import PurchaseForm from '@/components/purchase/PurchaseFormRefresh';
import { OnGenericPaymentSubmitRefresh } from '@/components/purchase/PurchaseFormRefresh/types';
import { useStripeElements } from '@/components/purchase/StripeElementsProvider';
import ValuePropsList from '@/components/valuePropList';
import { useAnalytics } from '@/hooks/analytics';
import { useSubPurchase } from '@/hooks/purchase/useSubPurchase';
import { usePartnerState, usePricesState, usePurchaseParamsState, useUserState } from '@/hooks/store';
import { useIsMobile } from '@/hooks/ui/useIsMobile';
import { useComponentDidMount } from '@/hooks/utils/useComponentDidMount';
import { userCanTrial } from '@/utils/subscriptions';

import { ValuePropsListWrapper } from '../Account/styles';
import ContentBasedImage from '../Components/ContentBasedImage';
import PartnershipValidation from '../Components/PartnershipValidation';
import QuizResults from '../Components/QuizResults';
import { useSimplifiedSignupContext } from '../Context';
import { PageWrap } from '../styles';
import messages from './messages';
import {
	BasicPropsList,
	LimitedTimeCallout,
	MobileValuePropsList,
	PaymentWrapper,
	PremiumPropsList,
	PurchaseFormWrapper,
	TwoColumnWrapper,
	ValuePropBackingText,
	ValuePropComparisonContainer,
} from './styles';
import { PaymentScreenConfig } from './types';

const Payment = () => {
	const {
		activeScreenConfig,
		name,
		fauxAuth,
		coBranded,
		screens: {
			account: { backgroundKey },
		},
		logPageViewEvent,
		flowConfig,
	} = useSimplifiedSignupContext();
	const {
		limitedTimeOffer,
		heading,
		upsellBullets = [],
		showHeader = true,
		valuePropBacking,
		contentBasedImage,
		basicLineHeader,
		basicLineItems = [],
		premiumLineItems = [],
		premiumLineHeader,
		isWithinModal,
		brandingPosition = 'right',
		terms,
		allowChurned,
		hidePaypal,
		hideValueProps,
		eventProps,
		endpoint,
	} = activeScreenConfig as PaymentScreenConfig;
	const { skippedToEnd = false, ActiveScreen, quizResults = false } = useContext(PreSignupFlowContext);
	const _submitSubPurchase = useSubPurchase();
	const { formatMessage } = useIntl();
	const { isStripeNextEnabled } = useStripeElements();
	const user = useUserState();
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const canTrial = userCanTrial(user);
	const { logEvent } = useAnalytics();
	const partner = usePartnerState();
	const { query } = useRouter();
	const isOrganicTraffic = query?.utm_medium !== 'paid';
	const [isMobile] = useIsMobile();
	const [landingEventFired, setLandingEventFired] = useState(false);
	const { email } = query;

	const [isVerified, setIsVerified] = useState(partner?.integration_type === 'DTC_CODELESS' || false);
	const [partnerUserId, setPartnerUserId] = useState(
		partner?.integration_type === 'DTC_CODELESS' ? 'DTC_CODELESS' : '',
	);

	useComponentDidMount(() => {
		logPageViewEvent('payment');
	});

	const { plan, purchaseType } = purchaseParams;

	const { message, placeholders = { dayNumber: isOrganicTraffic ? 14 : 7 } } = heading({
		trialLength: purchaseType?.duration && canTrial ? purchaseType.duration : 0,
		discountPercent: flowConfig && flowConfig.discount ? flowConfig.discount * 100 : undefined,
		prices,
		purchaseParams,
	});

	useEffect(() => {
		if ((partner && isVerified) || !partner) {
			if (landingEventFired) return;
			logEvent({
				eventName: 'Subscribe : Purchase : Form : Landed',
				eventProps: {
					source: name,
					is_stripe_next: isStripeNextEnabled,
					includes_quiz_recommendation: quizResults,
					...eventProps,
				},
			});
			setLandingEventFired(true);
		}
	}, [partner, isVerified, isStripeNextEnabled, name, logEvent, quizResults, landingEventFired, eventProps]);

	const showContentBasedImage =
		contentBasedImage || (ActiveScreen && ActiveScreen.upsellImage && skippedToEnd && !quizResults);

	const submitSubPurchase: OnGenericPaymentSubmitRefresh = args => {
		if (partner && partner.name.toLowerCase() !== 'aarp') {
			args = {
				...args,
				endpoint: `partnerships/${partner.id}/subscriptions`,
				dtc_partnership_details: {
					partner_id: partner.id,
					partner_user_id: partnerUserId,
				},
			};
		}
		if (email) {
			args = {
				...args,
				endpoint: 'subscription/email',
			};
		}
		return _submitSubPurchase({ ...args, endpoint: endpoint ?? args.endpoint });
	};

	const getBrandingHeader = () => {
		if (showHeader) {
			return null;
		}

		return <Branding backgroundColor={backgroundKey} coBranded={coBranded} />;
	};

	const getValuePropsOnMobile = () => {
		return !isMobile && (isWithinModal || !showContentBasedImage)
			? undefined
			: hideValueProps?.mobile
			? undefined
			: upsellBullets;
	};

	return (
		<PageWrap data-testid="PaymentScreen" isWithinModal={isWithinModal} isCompact={isWithinModal}>
			<TwoColumnWrapper isWithinModal={isWithinModal}>
				<ValuePropsListWrapper showHeader={showHeader} isWithinModal={isWithinModal}>
					{(brandingPosition === 'left' || isMobile) && getBrandingHeader()}
					{limitedTimeOffer && (canTrial || allowChurned) && (
						<LimitedTimeCallout>{formatMessage(messages.limitedTimeCallout)}</LimitedTimeCallout>
					)}
					{showContentBasedImage && (!isMobile || !isWithinModal) && (
						<ContentBasedImage {...contentBasedImage} />
					)}
					{!quizResults && (
						<Text el="h1" align="center" size={FontSizes['2xl']} weight={FontWeights.Medium}>
							{formatMessage(message, placeholders)}
						</Text>
					)}
					{quizResults && <QuizResults />}
					{premiumLineHeader && basicLineHeader ? (
						<ValuePropComparisonContainer>
							<BasicPropsList
								title={formatMessage(basicLineHeader, {
									br: <br></br>,
								})}
								copy={basicLineItems}
								buttonColor="gray4"
								textColor="black"
								propPadding={false}
								propSize={FontSizes.sm}
								fontWeight={FontWeights.Regular}
							/>
							<PremiumPropsList
								title={formatMessage(premiumLineHeader, {
									br: <br></br>,
								})}
								copy={premiumLineItems}
								buttonColor="blue3"
								textColor="black"
								propPadding={false}
								propSize={FontSizes.sm}
								fontWeight={FontWeights.Regular}
							/>
						</ValuePropComparisonContainer>
					) : (
						<ValuePropsList
							propSize={FontSizes.sm}
							copy={isMobile && (isWithinModal || !showContentBasedImage) ? undefined : upsellBullets}
							buttonColor="blue3"
							textColor="black"
							fontWeight={FontWeights.Medium}
							gapSize="20px"
						/>
					)}
					{valuePropBacking && (
						<em>
							<ValuePropBackingText skippedToEnd={skippedToEnd} el="p" align="left" size={FontSizes.sm}>
								{valuePropBacking}
							</ValuePropBackingText>
						</em>
					)}
				</ValuePropsListWrapper>
				<PaymentWrapper $overflow={isWithinModal && !isMobile}>
					{brandingPosition === 'right' && !isMobile && getBrandingHeader()}
					{partner && !isVerified && (
						<PartnershipValidation
							isVerified={isVerified}
							setIsVerified={setIsVerified}
							setPartnerUserId={setPartnerUserId}
						/>
					)}
					{email && fauxAuth && (
						<PurchaseFormSectionWrapper>
							<LoggedInAs email={email as string} />
						</PurchaseFormSectionWrapper>
					)}
					{((partner && isVerified) || !partner) && (
						<PurchaseFormWrapper>
							{isStripeNextEnabled ? (
								<PaymentElementsFormRefresh
									endpoint={
										partner?.id && partner?.name.toLowerCase() !== 'aarp'
											? `partnerships/${partner.id}/subscriptions`
											: email
											? 'subscription/email'
											: endpoint ?? 'subscription'
									}
									productInfo={{
										name: 'web_subscription',
										source: name,
										sku: plan,
										unit_price: purchaseType?.price ? prices.current[plan] / 100.0 : 0,
										quantity: 1,
									}}
									terms={terms}
									hidePaypal={hidePaypal}
									purchaseArgs={
										partner && partner.name.toLowerCase() !== 'aarp'
											? {
													partner_id: partner.id,
													partner_user_id: partnerUserId,
											  }
											: email
											? { email }
											: {}
									}
									successArgs={
										partner
											? {
													partner_id: partner?.id,
													partner_type: [partner?.category?.toLowerCase() || null],
											  }
											: {}
									}
								/>
							) : (
								<PurchaseForm
									onPurchase={submitSubPurchase}
									onSuccess={() => {}}
									hideTerms
									productInfo={{
										name: 'web_subscription',
										source: name,
										sku: plan,
										unit_price: purchaseType?.price ? prices.current[plan] / 100.0 : 0,
										quantity: 1,
									}}
									terms={terms}
									hidePaypal={hidePaypal}
									updateUserOnSuccess={true}
								/>
							)}
						</PurchaseFormWrapper>
					)}
				</PaymentWrapper>
				<MobileValuePropsList
					propSize={FontSizes.sm}
					copy={getValuePropsOnMobile()}
					buttonColor="blue3"
					textColor="black"
					fontWeight={FontWeights.Medium}
					gapSize="20px"
				/>
			</TwoColumnWrapper>
		</PageWrap>
	);
};

export default Payment;
